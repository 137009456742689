<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('系统管理.DIY项目管理.项目名称')">
                <a-input
                  v-model="queryParam.projectName"
                  :placeholder="$t('系统管理.DIY项目管理.请输入项目名称')"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('系统管理.DIY项目管理.联系人姓名')">
                <a-input
                  v-model="queryParam.contactsName"
                  :placeholder="$t('系统管理.DIY项目管理.请输入联系人姓名')"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{ $t('通用.查询') }}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"
                  ><a-icon type="redo" />{{ $t('通用.重置') }}</a-button
                >
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['system:post:add']">
          <a-icon type="plus" />{{ $t('通用.新增') }}
        </a-button>
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['system:post:edit']"
        >
          <a-icon type="edit" />{{ $t('通用.修改') }}
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['system:post:remove']">
          <a-icon type="delete" />{{ $t('通用.删除') }}
        </a-button>
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" :statusOptions="dict.type['sys_normal_disable']" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['system:post:edit']">
            <a-icon type="edit" />{{ $t('通用.修改') }}
          </a>
          <a-divider type="vertical" v-hasPermi="['system:post:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['system:post:remove']">
            <a-icon type="delete" />{{ $t('通用.删除') }}
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageProject, delProject } from '@/api/system/diy'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Diy',
  components: {
    CreateForm,
  },
  mixins: [tableMixin],
  dicts: ['sys_normal_disable'],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        projectName: undefined,
        contactsName: undefined,
      },
      columns: [
        {
          title: '项目名称',
          dataIndex: 'projectName',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'DIY模块项目ID',
          dataIndex: 'projectId',
          align: 'center',
        },
        {
          title: '联系人姓名',
          dataIndex: 'contactsName',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '联系人电话',
          dataIndex: 'contactsPhone',
          align: 'center',
        },
        {
          title: '数据库IP',
          dataIndex: 'dbIp',
          align: 'center',
        },
        {
          title: '数据库名称',
          dataIndex: 'databaseName',
          align: 'center',
        },
        {
          title: '数据库用户名',
          dataIndex: 'dbName',
          align: 'center',
        },
        {
          title: '数据库端口',
          dataIndex: 'dbPort',
          align: 'center',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          ellipsis: true,
          scopedSlots: { customRender: 'createTime' },
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '15%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
    }
  },
  filters: {},
  created() {
    this.getList()
    this.updateColumns()
  },
  computed: {},
  watch: {
    // 监听语言变化，语言可能存储在 Vuex 或 localStorage 中
    '$i18n.locale'(newLocale) {
      this.updateColumns() // 当语言变化时更新列配置
    },
  },
  methods: {
    /** 查询部门列表 */
    getList() {
      this.loading = true
      pageProject(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        projectName: undefined,
        contactsName: undefined,
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.postId)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const id = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中数据:' + row.projectName,
        onOk() {
          return delProject(id).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {},
      })
    },
    updateColumns() {
      // 根据当前语言获取表格列配置
      const currentLocale = this.$i18n.locale
      const columnsConfig = {
        en: [
          {
            title: 'project name',
            dataIndex: 'projectName',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'DIY module project ID',
            dataIndex: 'projectId',
            align: 'center',
          },
          {
            title: 'name of contact person',
            dataIndex: 'contactsName',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'phone number of the contact',
            dataIndex: 'contactsPhone',
            align: 'center',
          },
          {
            title: 'Database IP',
            dataIndex: 'dbIp',
            align: 'center',
          },
          {
            title: 'name of the database',
            dataIndex: 'databaseName',
            align: 'center',
          },
          {
            title: 'Database user name',
            dataIndex: 'dbName',
            align: 'center',
          },
          {
            title: 'Database port',
            dataIndex: 'dbPort',
            align: 'center',
          },
          {
            title: 'createTime',
            dataIndex: 'createTime',
            ellipsis: true,
            scopedSlots: { customRender: 'createTime' },
            align: 'center',
          },
          {
            title: 'operation',
            dataIndex: 'operation',
            width: '15%',
            scopedSlots: { customRender: 'operation' },
            align: 'center',
          },
        ],
        'zh-CN': [
          {
            title: '项目名称',
            dataIndex: 'projectName',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'DIY模块项目ID',
            dataIndex: 'projectId',
            align: 'center',
          },
          {
            title: '联系人姓名',
            dataIndex: 'contactsName',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '联系人电话',
            dataIndex: 'contactsPhone',
            align: 'center',
          },
          {
            title: '数据库IP',
            dataIndex: 'dbIp',
            align: 'center',
          },
          {
            title: '数据库名称',
            dataIndex: 'databaseName',
            align: 'center',
          },
          {
            title: '数据库用户名',
            dataIndex: 'dbName',
            align: 'center',
          },
          {
            title: '数据库端口',
            dataIndex: 'dbPort',
            align: 'center',
          },
          {
            title: '创建时间',
            dataIndex: 'createTime',
            ellipsis: true,
            scopedSlots: { customRender: 'createTime' },
            align: 'center',
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width: '15%',
            scopedSlots: { customRender: 'operation' },
            align: 'center',
          },
        ],
      }
      this.columns = columnsConfig[currentLocale] || columnsConfig['en']
    },
  },
}
</script>
